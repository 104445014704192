import React from 'react';
import { Shield, Target, Star, Zap, Music, Book, Users, Gitlab } from 'react-feather';
import '../style/discover.css';

interface Server {
  name: string;
  description: string;
  members: string;
  online: string;
  image: string;
  featured?: boolean;
  icon: React.ReactNode;
}

const Discover: React.FC = () => {
  const servers: Server[] = [
    {
      name: "Genshin Impact Community",
      description: "Welcome, Traveler! Join the largest Genshin Impact community and explore the world of Teyvat together!",
      members: "1.9M",
      online: "476.2K",
      image: "/api/placeholder/800/400",
      featured: true,
      icon: <Shield size={28} className="icon" />
    },
    {
      name: "Gaming Central",
      description: "The biggest gaming community! All about games, e-sports, and gaming culture in one place.",
      members: "856.4K",
      online: "234.5K",
      image: "/api/placeholder/800/400",
      icon: <Target size={28} className="icon" />
    },
    {
      name: "Anime & Manga",
      description: "A community for anime fans! Discussions, events, and group watch parties in a friendly environment.",
      members: "654.3K",
      online: "123.4K",
      image: "/api/placeholder/800/400",
      icon: <Star size={28} className="icon" />
    },
    {
      name: "Tech Hub",
      description: "For lovers of technology, programming, and innovation. Let's grow and learn together!",
      members: "543.2K",
      online: "98.7K",
      image: "/api/placeholder/800/400",
      icon: <Zap size={28} className="icon" />
    },
    {
      name: "Music Lovers",
      description: "A space for musicians and music fans to share and discuss all things music!",
      members: "412.1K",
      online: "85.3K",
      image: "/api/placeholder/800/400",
      icon: <Music size={28} className="icon" />
    },
    {
      name: "Book Club",
      description: "Join fellow book lovers to discuss your favorite reads, authors, and literary genres!",
      members: "320.5K",
      online: "72.4K",
      image: "/api/placeholder/800/400",
      icon: <Book size={28} className="icon" />
    },
    {
      name: "Open Source Devs",
      description: "A community for open-source enthusiasts, developers, and contributors!",
      members: "278.9K",
      online: "65.2K",
      image: "/api/placeholder/800/400",
      icon: <Gitlab size={28} className="icon" />
    },
    {
      name: "Social Hangout",
      description: "A casual, friendly place to meet new people and chat about anything!",
      members: "510.6K",
      online: "112.3K",
      image: "/api/placeholder/800/400",
      icon: <Users size={28} className="icon" />
    }
  ];

  return (
    <div id="discover-container">
      <h1 className="title">Find your comunity</h1>
      <div className="grid-container">
        {servers.map((server, index) => (
          <div key={index} className="card">
            <img src={server.image} alt={server.name} className="card-image" />
            <div className="card-content">
              <div className="card-header">
                {server.icon}
                <h2 className="card-title">{server.name}</h2>
              </div>
              <p className="card-description">{server.description}</p>
              <div className="card-info">{server.members} member • {server.online} online</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discover;