import React from 'react';
import '../style/ServerList.css';

interface ServerList {serverWheel: () => void;}
const serverList: React.FC<ServerList> = ({ serverWheel }) => {
  return (
    <div className="server-list" id="radial-menu">
        <div onClick={serverWheel} className="hex-content" id="home">
            <i className="bi bi-x"></i>
        </div>

        <div className="hex-menu">
            <div className="menu-hex-container active">
                <div className="menu-hex"></div>
                <span className="menu-hex-label">Servers</span>
            </div>
        </div>

        <div className="radial-menu">
            <button className="menu-item active">
                <i className="bi bi-house-fill"></i>
            </button>
                <button className="menu-item image-hex" style={{ backgroundImage: "url('https://cdn.discordapp.com/icons/1242297152970166282/8cc847b2842b286b78517050f4c257c8.webp?size=96'" }}>
            </button>
            <button className="menu-item">
                <i className="bi bi-star-fill"></i>
            </button>
            <button className="menu-item">
                <i className="bi bi-people-fill"></i>
            </button>
            <button className="menu-item">
                <i className="bi bi-gear-fill"></i>
            </button>
            <button className="menu-item">
                <i className="bi bi-trophy-fill"></i>
            </button>
            <button className="menu-item">
                <i className="bi bi-controller"></i>
            </button>
            <button className="menu-item">
                <i className="bi bi-chat-fill"></i>
            </button>

            <div className="center-hex">
                <img src="logo.png" alt=""></img>
            </div>
        </div>
    </div>
    );
};

export default serverList;