import React, { useState } from 'react';
import '../style/settings.css';

interface User {
  displayname: string;
  email: string;
  pfp: string;
}

interface SettingsProps {
  user: User | boolean;
  logout: () => void;
}

const Settings: React.FC<SettingsProps> = ({ user, logout }) => {
  const [editingField, setEditingField] = useState<string | null>(null);
  const [updatedValues, setUpdatedValues] = useState({
    displayname: user && typeof user === 'object' ? user.displayname : 'Guest',
    email: user && typeof user === 'object' ? user.email : 'Guest',
  });

  const handleEdit = (field: string) => {
    setEditingField(field);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedValues({ ...updatedValues, [editingField!]: e.target.value });
  };

  const handleSave = () => {
    setEditingField(null);
  };

  return (
    <div className="settings-content-container" id="settings">
      <div className="settings-main-content">
        <div className="settings-profile-section">
          <div className="settings-profile-header">
            <img src={user && typeof user === 'object' ? user.pfp : 'G'} alt='' />
            <div className="settings-profile-info">
              <h2>{updatedValues.displayname}</h2>
              <p>Member since: 2022</p>
            </div>
          </div>
          <h3 className="settings-section-title">Account info</h3>
          <div className="settings-profile-item">
            <div>
              <strong>Display name:</strong> 
              {editingField === 'displayname' ? (
                <input type="text" value={updatedValues.displayname} onChange={handleChange} />
              ) : (
                updatedValues.displayname
              )}
            </div>
            {editingField === 'displayname' ? (
              <button onClick={handleSave}>Save</button>
            ) : (
              <button onClick={() => handleEdit('displayname')}>Edit</button>
            )}
          </div>
          <div className="settings-profile-item">
            <div>
              <strong>E-mail:</strong> 
              {editingField === 'email' ? (
                <input type="text" value={updatedValues.email} onChange={handleChange} />
              ) : (
                updatedValues.email
              )}
            </div>
            {editingField === 'email' ? (
              <button onClick={handleSave}>Save</button>
            ) : (
              <button onClick={() => handleEdit('email')}>Edit</button>
            )}
          </div>
          <div className="settings-profile-item">
            <div>
              <strong>Telefonszám:</strong> *****9359 
            </div>
            <div className="button-container">
              <button>Show</button>
              <button className="settings-danger">Delete</button>
            </div>
          </div>
          <h3 className="settings-section-title">Password and Authentication</h3>
          <div className="settings-profile-item">
            <button>Change password</button>
          </div>
          <div className="settings-profile-item">
            <button onClick={logout}>Log out</button>
            <button>Delete account</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;