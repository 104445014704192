import React, {useRef,useEffect, useState, useCallback} from 'react';
import ContextMenu from './ContextMenu';
import '../style/openchannel.css';

interface channels { 
    channelData: ChannelData; 
    user: User | false; 
    selectedChannel: Channel | null; 
    wsSend: (message: wsMessage) => void; 
    sendMessage: (message: Message) => void
    reply: Message | undefined
    setReply: (message: Message) => void
}

const ChannelOpen: React.FC<channels> = ({ channelData, selectedChannel, sendMessage, user, wsSend, reply, setReply }) => {
    const [message, setMessage] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [channelData]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && message.trim() !== '') {
            if (!user) { return }
            if (!selectedChannel?.channel_id) { return }
            const tempId = 'temp-' + Date.now();
            const messageSent: Message = {message_id: tempId, user_id: user.user_id, content: message, timestamp: "just now", username: user.username, reactions: []} 
            sendMessage(messageSent);
            wsSend({op: "sendMessage", d: {...messageSent, channelId: selectedChannel?.channel_id}})
            setMessage('');
        }
    };


    const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [contextData, setContextData] = useState<{ type: 'message', channelData: ChannelData, selectedChannel: Channel, message: Message } | null>(null);

    const handleContextMenu = (e: React.MouseEvent, message: Message) => {
      e.preventDefault();
      const x = e.clientX;
      const y = e.clientY;
      setMenuPosition({ top: y, left: x });
      setContextData({type: 'message', channelData: channelData, selectedChannel: selectedChannel!, message: message})
      setMenuOpen(true);
    };

    const cancelContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
    };
    
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };

    const handleEvents = useCallback((event: string, dataaray: Message | Channel | User | undefined) => {
        if (!dataaray) { return }
        // TODO: edit, pin, share, save, moderate, translate, delete
        if (event === 'copy') {
            const messageContent = (dataaray as Message).content;
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(messageContent)
                .catch(err => console.error('Failed to copy text:', err));
            } else {
              console.error('Clipboard API not supported');
            }
        }else if (event === 'reply') {
            setReply(dataaray as Message)
        }
        handleCloseMenu()
    },[setReply])

    return (
        <div className="main-content" id="open-channel">
            <div className="chat-header">
                <div>
                    <h2>{channelData.channelinfo?.name}</h2>
                    <p>{channelData.channelinfo?.description}</p>
                </div>
                <div className='button'>
                    <button><i className="bi bi-search"></i></button>
                    <button><i className="bi bi-pin-fill"></i></button>
                </div>
            </div>

            <div onContextMenu={(e) => cancelContextMenu(e)} className="messages-area" id="messages">
                {!selectedChannel && 
                    <div className='no-channels'>
                        <i className="bi bi-cone-striped"></i>
                        <h1>No Server or Channel Selected</h1>
                        <p><strong>To join servers,</strong> simply accept an invite or use the discovery tool to explore community servers you may be interested in joining.</p>
                        <p><strong>To create a new server,</strong> click the plus icon in the bottom left corner of your screen.</p>
                        <p><strong>To add channels,</strong> right-click in the channels section and select "Create Channel" from the options.</p>
                    </div>
                }
                {channelData.messages && channelData.messages.map((message) => (
                    <div onContextMenu={(e) => handleContextMenu(e, message)}>
                        <div className="message" key={message.message_id} data-message-id={message.message_id}>
                            <div className="user-avatar">
                                <img src={`/pfp/${message.user_id}.png`} alt="User Avatar" />
                            </div>
                            <div className="message-content">
                                <div className="message-header">
                                    <span className="user-name">{message.username}</span>
                                    <span className="timestamp">{message.timestamp}</span>
                                </div>
                                <div className="message-text">{message.content}</div>
                                <div className="reactions">
                                    {message.reactions.map((reaction, index) => (
                                        <span key={index} className="reaction">
                                        {reaction.type} {reaction.count}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <ContextMenu
                    open={menuOpen}
                    position={menuPosition || { top: 0, left: 0 }}
                    contextData={contextData}
                    
                    onClose={handleCloseMenu}
                    eventHandler={handleEvents}
                />
                <div ref={messagesEndRef} />
            </div>

            <div className="chat-input-container">
                {reply &&
                    <div className="reply">
                        <span className="user-name">{reply.username}</span>
                        <span className="reply-spacing">:</span>
                        <span className="message-text">{reply.content}</span>
                    </div>
                }
                <input type="text" className="chat-input" placeholder='Try saying "hello guys!"' value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={handleKeyPress}/>
            </div>
        </div>
    );
};

export default ChannelOpen;